import React from "react";
import * as s from "./hero.module.css";

const IndustryHero = ({title, description}) => (
  <section className={s.section}>
    <div className={s.content}>
      <h1 className={s.title}>{title}</h1>
      <p className={s.description}>{description}</p>
    </div>
  </section>
)

export default IndustryHero