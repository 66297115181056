import React, { useEffect, useRef, useState } from "react";
import { PortableText } from "@portabletext/react";
import { ScrollTrigger } from "gsap/all";
import Image from "gatsby-plugin-sanity-image";
import * as s from "./section.module.css";


const IndustrySection = ({nodes}) => {
  const [active, setActive] = useState(0);

  return (
    <section className={s.wrapper}>
      <Gallery>
      {nodes.map(
        (v, num) => (
          <Image
            key={v._key}
            className={statefulClassName(num, active, s.image)}
            {...v.image}
            alt=""
            config={{
              quality: 90
            }}
          />
        )
      )}
      </Gallery>
      <ContentWrapper>
      {nodes.map(
        (v, num) => (
          <Section
            key={v._key}
            className={statefulClassName(num, active, s.section)}
            title={v.title}
            content={v._rawContent}
            onEnter={() => setActive(num)}
          />
        )
      )}
      </ContentWrapper>
    </section>
  )
}

const ContentWrapper = ({children}) => {
  const ref = useRef();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    ScrollTrigger.matchMedia({
      "(max-width: 767px)" : function() {
        ScrollTrigger.create({
          trigger: ref.current.parentNode,
          start: `start 15%`,
          end: `bottom 15%`,
          onUpdate: self => setOffset(self.progress * (self.end - self.start)),
        })
      }
    })
  }, []);

  return (
    <div className={s.contentWrapper} ref={ref} style={{
      '--top-offset': `${offset}px`,
    }}>
      {children}
    </div>
  )
}


const Section = ({title, content, className, onEnter}) => {
  const ref = useRef();
  const [trigger, setTrigger] = useState();

  useEffect(() => {
    ScrollTrigger.matchMedia({
      "(max-width: 767px)" : function() {
        if (trigger) return;
        const temp = ScrollTrigger.create({
          trigger: ref.current,
          start: "top 60%",
          end: "bottom 60%",
          onEnter: onEnter,
          onEnterBack: onEnter,
        })
        setTrigger(temp);
      },
      "(min-width: 768px)" : function() {
        if (trigger) return;
        const temp = ScrollTrigger.create({
          trigger: ref.current,
          start: "top center",
          end: "bottom center",
          onEnter: onEnter,
          onEnterBack: onEnter,
       })
       setTrigger(temp);
      }
    })
  }, [onEnter, trigger, setTrigger]);

  return (
    <section ref={ref} className={className}>
      <h2 className={s.title}>{title}</h2>
      <div className={s.content}>
        <PortableText value={content} />
      </div>
    </section>
  )
}

const Gallery = ({children}) => {
  const gallery = useRef();
  const wrapper = useRef();
  
  useEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width: 768px)" : function() {
        ScrollTrigger.create({
          trigger: gallery.current,
          start: () => `${wrapper.current.clientHeight / 2} center`,
          end: () => `100%-=${wrapper.current.clientHeight / 2}px center`,
          pin: wrapper.current,
          pinSpacing: false,
        })
      }
    })
  }, []);

  return (
    <div className={s.gallery} ref={gallery}>
      <div className={s.imagesWrapper} ref={wrapper}>
        {children}
      </div>
    </div>
  )
}

const statefulClassName = (id, current, className) => {
  const stateClassName = id === current ? s.current : id > current ? s.future : s.past;
  return `${className} ${stateClassName}`
}

export default IndustrySection