// extracted by mini-css-extract-plugin
export var content = "section-module--content--44b9f";
export var contentWrapper = "section-module--content-wrapper--d7178";
export var current = "section-module--current--c099a";
export var future = "section-module--future--6c414";
export var gallery = "section-module--gallery--5ea0d";
export var image = "section-module--image--1c18d";
export var imagesWrapper = "section-module--images-wrapper--0a94e";
export var past = "section-module--past--f80a8";
export var section = "section-module--section--1b6f6";
export var title = "section-module--title--b06fc";
export var wrapper = "section-module--wrapper--2da44";