import React from "react"
import { graphql } from "gatsby"
import {Layout} from "../components"
import {Hero, Section} from "../components/industryPage";

const IndustriesPage = ({data}) => {
  const {heroTitle, heroDescription, industries, seoTitle, seoDescription} = data.sanityIndustries
  return (
  <Layout
    seo={{
      title: seoTitle,
      description: seoDescription
    }}
    floatingHeader={true}
  >
    <Hero title={heroTitle} description={heroDescription} />
    <Section nodes={industries} />
  </Layout>
)}

export const pageQuery = graphql`
  query IndustriesPageQuery {
    sanityIndustries {
      heroTitle
      heroDescription
      seoTitle
      seoDescription
      industries {
        _key
        title
        _rawContent
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`

export default IndustriesPage